import React from "react";
import Button from "../../components/bootstrap/Button";
import Carousel from "./Carousel";
import '../../styles/components/SinglePage/_rol.scss'
import { FaArrowRight } from "react-icons/fa";

const Rol = () => {

    return (
        <section className="rol">
            <div className="row">
                <div className='col-md-5 carousel-imgs'>
                    <Carousel />
                </div>
                <div className="col-md-6 offset-md-1 offset-0 pt-5 pt-sm-0 pt-md-0   info" >
                    <div className="_content">
                        <h1 className="fw-bold text-dark title">Get better results and <span className="text-success">ROl</span></h1>
                        <p className="desc">
                            When your social strategy is executed well and informed by robust, unique insights you’ll get a competitive edge that produces measurable results. <span className="fw-bold">Don’t just take our word for it, see how some of our other clients did it.</span>
                        </p>
                        <Button className="btn btn-outline-success text-dark  px-4 py-3 d-flex mt-5">Get started <FaArrowRight  className="mt-1 ms-3"/>
                        </Button>                        
                        </div>
                </div>

            </div>
        </section>
    )
}
export default Rol