import React, { useState } from "react";
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai'
import '../../styles/components/SinglePage/_mainsearch.scss'
const TopBar = () => {

    const navs = [
        {
            nav: "ParrotIQ academy",
            sm: "none"
        },
        {
            nav: "Forrest wave",
            sm: "none"

        },
        {
            nav: "Carrers",
            sm: "none"

        },
        {
            nav: "Contact",
            sm: "block"

        },
        {
            nav: "Support",
            sm: "block"

        },
        {
            nav: "Community",
            sm: "block"

        },

    ]

    const [openSearch,setOpenSearch]=useState(false)

    return (
        <>
            <header className="bg-light sticky-top p-1">
                <div className="container">
                    <ul className="d-flex justify-content-end ">
                        {navs.map((item, index) => {
                            return (
                                <li key={index} className={`text-secondary fw-semibold text-uppercase border-start px-4 cursor-pointer pt-1 d-md-block d-${item.sm}`} style={{ fontSize: "10px" }}>{item.nav}</li>
                            )
                        })}
                        <li className="text-secondary fw-semibold text-uppercase border-start px-4 cursor-pointer" style={{ fontSize: "15px" }} onClick={()=>setOpenSearch(true)}><AiOutlineSearch /></li>
                    </ul>
                </div>
            </header>
            {openSearch && <section className='main-bg--search d-flex justify-content-center'>
                <div className="col-xl-5 col-lg-6 col-md-8 col-10 main-search">
                    <div className="d-flex gap-2 border-bottom">
                        <AiOutlineSearch className="search-icon " />
                        <input type="text" placeholder="Search..." className="form-control shadow-none border-0 text-white" />

                        <div className="d-flex justify-content-end " onClick={()=>setOpenSearch(false)}>
                            <span className="badge d-flex search-close--badge cursor-pointer">
                                <AiOutlineClose className="close-icon fw-semibold text-white" style={{ fontSize: "25px" }} />
                            </span>
                        </div>
                    </div>
                </div>
            </section>}
        </>

    )
}
export default TopBar