import React from "react";
import Img1 from '../../assets/img/New folder/6.jpg'
import Component from "./Component";
const Collaborate = () => {
    const collaborate = [
        {
            img: Img1,
            imgWidth:"85%",
            imgHight:"70vh",
            title: <div><span className="text-success">Collaborate</span>  on data-driven content   </div>,
            description:
                <div> Manage channels, teams, workflows, approvals, and campaigns and <span className="fw-bold">ensure brand alignment and stellar quality </span>in one collaborative content calendar.</div>,
            path: "",
            order: "order-2",
             top:"100px"
        }
    ]
    return (
        <section>
            {collaborate.map((item, index) => {
                return (
                    <Component key={index} {...item} />
                )
            })}
        </section>
    )
}
export default Collaborate