import React from "react";
import Img1 from '../../assets/img/New folder/7.jpg'
import Component from "./Component";
const Threat = () => {
    const threat = [
        {
            img: Img1,
            imgWidth: "90%",
            imgHight: "90vh",
            title: <div>Shield your brand from <span className="text-light--red">threats</span></div>,
            description: <div>Monitor emerging threats across over 100m sources and set up smart, real-time alerts to <span className="fw-bold">respond with speed and confidence before problems develop.</span></div>,
            path: ""
        }
    ]
    return (
        <section>
            {threat.map((item, index) => {
                return (
                    <Component key={index} {...item} />
                )
            })}
        </section>
    )
}
export default Threat