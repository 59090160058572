import React from 'react'
import Typewrite from './Typewriter'
import "../../styles/components/SinglePage/_singlepage.scss"
import SocialChannel from './SocialChannel'
import Brandlogo from './BrandLogo'
import Faqs from './Faqs'
import Trend from './Trend'
import Collaborate from './Collaborate'
import Threat from './Threats'
import Manage from './Manage'
import Review from './Review'
import Solution from './Solution'
import EnterPrice from './EnterPrise'
import Rol from './Rol'

const Singlepage = () => {
    return (

        <section>
            <div className='container'>
                <div className='col-12'>
                    <Typewrite />
                </div>
                <div className='col-12 mt-3'>
                    <SocialChannel />
                </div>

                <div className='col-12 mt-5'>
                    <li className='fw-semibold text-secondary brand_title px-3 px-sm-0 ps-4 ps-sm-0'>TRUSTED BY THOUSANDS OF THE WORLD’S BIGGEST BRANDS</li>
                    <div className='pt-md-4 pt-sm-2 pt-0'>
                        <Brandlogo />
                    </div>
                </div>

                <div className='col-12'>
                    <Faqs />
                </div>

            </div>

            <div className='container-fluid'>
                <div className='col-12 mt-5'>
                    <Trend />
                </div>
            </div>
            <div className='container'>
                <div className='col-12'>
                    <Collaborate />
                </div>
            </div>
            <div className='container-fluid'>
                <div className='col-12'>
                    <Threat />
                </div>
            </div>
            <div className='container-fluid'>
                <div className='col-12'>
                    <Manage />
                </div>
            </div>
            <div className='container'>
                <div className='col-12 mt-5'>
                    <Rol />
                </div>

                <div className='col-12 '>
                    <Review />
                </div>
                <div className='col-12 mt-5 mt-sm-0'>
                    <Solution />
                </div>
                <div className='col-12'>
                    <EnterPrice />
                </div>

            </div>
            <br />
        </section>
    



    )
}
export default Singlepage