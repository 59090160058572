import React from "react";
import '../../styles/components/SinglePage/_demo.scss'
import { AiOutlineCheck } from 'react-icons/ai'
import partner1 from '../../assets/img/carosal/partner-logo-2.png'
import partner2 from '../../assets/img/carosal/partner-logo.png'
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import { Link } from "react-router-dom";
const Demo = () => {
    const year = new Date().getFullYear()

    const meetBook = [
        {
            heading: "Get a personalized demo of ParrotIQ"
        },
        {
            heading: "Discuss your organization’s use cases, challenges and goals"
        },
        {
            heading: "Explore growth opportunities and risks in your market for 2023"
        },
    ]

    const demoBottomNav = [
        {
            nav: "Contact Us"
        },
        {
            nav: "User Privacy Statement"
        },
        {
            nav: "Author Privacy Statement"
        },
        {
            nav: "Terms & Conditions "
        },
    ]

    return (
        <>
            <section className="demo">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5  meetBook text-white">
                            <div className="_contant  col-12 col-xl-12 col-lg-6 col-md-8 col-sm-11">
                                <div className="top-content">
                                    <h1 className="title fw-semibold pb-3 text-center text-xl-start"><span className="text-success">
                                        Choose your time</span> to speak to us</h1>
                                    <p className="w-xl-75 text-center text-xl-start px-5">Want to see how ParrotIQ can help you better understand and engage with your consumers?</p>
                                    <p className="w-xl-75 text-center text-xl-start px-5">Fill out the form to pick your time from the calendar and book a meeting.</p>
                                </div>
                                <h3 className="fw-semibold pt-5 meet-book--title">Book a meeting with our experts to :</h3>
                                <hr className="my-4" />
                                <div className="meet-book">
                                    {meetBook.map((item, index) => {
                                        return (
                                            <div className="d-flex gap-3 my-4" key={index}>
                                                <div className="badge bg-success rounded-circle fw-semibold">
                                                    <AiOutlineCheck className="check-icon" />
                                                </div>
                                                <span style={{ color: "#c6c7cc" }} >{item.heading}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="partners mt-5">
                                    <div className="d-flex gap-2 mb-4">
                                        <h6 className="text-nowrap pt-2">OUR PARTNERS</h6> <hr className="m-0" />
                                        <hr className="w-100 " />

                                    </div>
                                    <div className="company-logo--1 mb-5">
                                        <img src={partner1} alt="" className="img-fluid" />
                                    </div>
                                    <div className="company-logo--2">
                                        <img src={partner2} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5 meetBook offset-xl-1 mt-5 mt-xl-0">
                            <div className="_contant col-12 col-xl-12 col-lg-6 col-md-8 col-sm-11">
                                <Card className="rounded-1">
                                    <CardBody>
                                        <span className="d-flex justify-content-end mb-2" style={{ fontSize: "12px" }}>* Indicates a required field</span>
                                        <form className='row g-2'>
                                            <div className='col-12'>
                                                <label htmlFor="" className="fw-semibold ps-1 pb-1 text-secondary">First Name *</label>
                                                <input type='text' autoComplete='off' className="form-control py-3 rounded-1"
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <label htmlFor="" className="fw-semibold ps-1 pb-1 text-secondary">Last Name *</label>
                                                <input type='text' autoComplete='off' className="form-control py-3 rounded-1"
                                                />

                                            </div>
                                            <div className='col-12'>
                                                <label htmlFor="" className="fw-semibold ps-1 pb-1 text-secondary">Email Adress *</label>
                                                <input type='email' autoComplete='off' className="form-control py-3 rounded-1"
                                                />

                                            </div>
                                            <div className='col-12'>
                                                <label htmlFor="" className="fw-semibold ps-1 pb-1 text-secondary">Company *</label>
                                                <input type='text' autoComplete='off' className="form-control py-3 rounded-1"
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <label htmlFor="" className="fw-semibold ps-1 pb-1 text-secondary">Country *</label>
                                                <input type='text' autoComplete='off' className="form-control py-3 rounded-1"
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <label htmlFor="" className="fw-semibold ps-1 pb-1 text-secondary">Industry *</label>
                                                <input type='text' autoComplete='off' className="form-control py-3 rounded-1"
                                                />

                                            </div>
                                            <div className='col-12'>
                                                <label htmlFor="" className="fw-semibold ps-1 pb-1 text-secondary">Phone Number *</label>
                                                <input type='text' autoComplete='off' className="form-control py-3 rounded-1"
                                                />
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <label htmlFor="" className="fw-semibold ps-1 pb-1 text-secondary">
                                                    Sign up to get new insights, research tips, event invites, & product news. You can unsubscribe at any time *
                                                </label>
                                                <input type='text' autoComplete='off' className="form-control py-3 rounded-1"
                                                />

                                            </div>
                                            <hr className="my-4" />
                                            <div className="col-12">
                                                <Button
                                                    color='success'
                                                    className='w-100 py-3 rounded-1 fw-semibold mb-3 fs-5'
                                                >
                                                    Book a Meeting
                                                </Button>
                                                <span style={{ fontSize: "11px" }}>By submitting this form you agree to ParrotIQ’ <a className="text-success fw-semibold" href="#">user privacy statement.</a></span>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="demo-bottom">
                <div className="container">
                    <div className="d-flex justify-content-center text-center py-5">
                        <div className="col-lg-8 col-xl-6 col-md-10 col-12">
                            <ul className="d-lg-flex justify-content-lg-between">
                                {demoBottomNav.map((item, index) => {
                                    return (
                                        <li className="fw-semibold pt-3 pt-lg-0" key={index}>
                                            <Link to="#" className="text-decoration-none text-dark">{item.nav}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                            {/* <div >
                                <span className="d-block pb-3"> Copyright &copy; {year} ParrotIQ All Rights Reserved.</span>
                                <span> 1st Floor, Sovereign House, Church Street, Brighton, BN1 1UJ <br /> Company number: 0311 111111 | VAT number: 754 750 710</span>

                            </div> */}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Demo