import React from 'react'
import '../../styles/components/SinglePage/_enterprise.scss'
import Button from '../../components/bootstrap/Button'
import { FaArrowRight } from "react-icons/fa";
import Card, { CardBody } from '../../components/bootstrap/Card'
import Img1 from '../../assets/img/carosal/img1.jpg'
import Img2 from '../../assets/img/carosal/img2.jpg'
import Img3 from '../../assets/img/carosal/img3.jpg'
import Img4 from '../../assets/img/carosal/img4.jpg'
const EnterPrice=()=>{

    const enterPriseLogo=[
        {
            img:Img1
        },
        {
            img:Img2
        },
        
        {
            img:Img3
        },
        
        {
            img:Img4
        },
        
        
    ]

    return(
        <section className='enter-prise'>
           <div className="row">
                <div className="col-md-6 info mb-4">
                   <div className='_content'>
                   <h1 className="fw-bold text-dark title ">The <span className="text-success">#1</span> trusted enterprise solution</h1>
                    <p className="desc fw-normal">

                    ParrotIQ solutions are consistently recognized as leaders by industry analysts and the customers who rely on them to better understand and engage with their consumers.                    
                    
                    </p>
                    
                    <Button className="btn btn-outline-success text-dark  px-4 py-3 d-flex mt-5">Get started <FaArrowRight   className="mt-1 ms-3" />
                        </Button>                
                   </div>
                </div>
                <div className='col-md-4 enter-prise--logo'>
                  
                 <div className='_content'>
                 <div className='row'>
                    {enterPriseLogo.map((item,index)=>{
                        return(
                            <div className='col-6' key={index}>
                            <Card >
                                <img src={item.img} alt="" className='img-fluid w-100'/>
                            </Card>
                        </div>
                        )
                    })}
                   
                   </div>
                 </div>
                </div>
            </div>






        </section>
    )
}

export default EnterPrice