import React from 'react'
import Img1 from '../../assets/img/carosal/unliver.png'
import Img2 from '../../assets/img/carosal/gsk.png'
import Img3 from '../../assets/img/carosal/nestle-3-logo-png-transparent.png'
import Img4 from '../../assets/img/carosal/carlsberg-logo-black-and-white.png'
import Img5 from '../../assets/img/carosal/toyota.png'
import Img6 from '../../assets/img/carosal/tui.png'
import '../../styles/components/SinglePage/_brandlogo.scss'
const Brandlogo = () => {

    const brandLogo = [
        {
            img: Img1
        },
        {
            img: Img2
        },

        {
            img: Img3
        },

        {
            img: Img4
        },
        {
            img: Img5
        },

        {
            img: Img6
        },

    ]
    return (
        <section className='brand-logo'>
            <div className='_content'>
            <div className='row g-5'>
                {brandLogo.map((item, index) => {
                    return (
                        <div className='col-md-2 col-sm-6 mb-4 col-6' key={index}>
                            <img src={item.img} alt="" className='img-fluid' />
                        </div>
                    )
                })}
            </div>
            </div>
        </section>
    )
}
export default Brandlogo