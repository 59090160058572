import React, { useState } from 'react'
import "../../styles/components/SinglePage/_faqs.scss"
import Img1 from '../../assets/img/New folder/1.svg'
import Img2 from '../../assets/img/New folder/2.svg'
import Img3 from '../../assets/img/New folder/3.jpg'
import Img4 from '../../assets/img/New folder/4.jpg'

const Faqs = () => {
    const faqs = [
        {
            id: 1,
            title: "Conduct deep research",
            description: "Anticipate consumer demand by bringing structure and meaning to billions of voices",
            image: Img1,

        },

        {
            id: 2,
            title: "Monitor your brand",
            description: "Survive and thrive in a fast-moving digital landscape by keeping your finger on the pulse of consumer opinion",
            image: Img2,

        },

        {
            id: 3,
            title: "Create winning content",
            description: "Grow your brand with a data-driven content strategy all managed in one collaborative social content calendar",
            image: Img3,

        },
        {
            id: 4,
            title: "Engage with consumers",
            description: "Deliver exceptional customer experience by engaging with the right people in the right place at the right time",
            image: Img4,
            shadow: "shadow-none"

        },
    ]
    const [isOpen, setIsOpen] = useState(0)

    const handleOpen = (i: any) => {
        if (isOpen === i) {
            setIsOpen(0)
        } else {
            setIsOpen(i)

        }

    }



    return (
        <section className='faqs'>
            <h1 className='text-dark fw-semibold'>
                A complementary suite<br />
                of specialized, best in class tools</h1>

            <div className='row mt-5'>
                <div className='col-xl-4 '>
                    {faqs.map((item, index) => {
                        const { id, title, description } = item
                        return (
                            <div className='tabs  d-flex gap-3 mb-2' key={index}>
                                <div className={`badge bg-success rounded-circle d-flex justify-content-center   ${isOpen === index ? "" : "opacity-25"} `}>{id}</div>
                                <div>
                                    <h3 className={`fw-semibold text-dark cursor-pointer ${isOpen === index ? "" : "opacity-25"}`} onClick={() => handleOpen(index)}>{title}</h3>
                                    {isOpen === index && (<p className='text-secondary fw-normal pt-2'>{description}</p>)}

                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='col-xl-8 mb-lg-5 mt-4 mt-xl-0'>
                    {faqs.map((item, index) => {
                        return (
                            <div key={`'img'+${index}`}>
                                {isOpen === index && (<img src={item.image} alt='' className={`img-fluid w-100 ${item.shadow}`} />
                                )}
                            </div>
                        )
                    })}
                </div>

            </div>
        </section>
    )
}
export default Faqs