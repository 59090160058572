import React from 'react'
import { Typewriter } from 'react-simple-typewriter'
import '../../styles/components/SinglePage/_typewritte.scss'
import Button from '../../components/bootstrap/Button'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Typewrite = () => {

    const handleType = (count: number) => {
        // access word count number
        console.log(count)
    }


    const handleDone = () => {
        console.log(`Done after 5 loops!`)
    }

    let type1 = "Understand + engage"

    let type2 = "Understand + collaborate"

    let type3 = "Monitor + respond"

    let type4 = "Monitor + engage"

    let type5 = "Discover + respond"

    let type6 = "Discover + engage"

    let type7 = "Analyze + create"

    let type8 = "Analyze + collaborate"


    return (
        <section className='typewrite'>
            <h1 className='fw-bold text-dark'>
                <span className='text-success fw-bold'>
                    {/* Style will be inherited from the parent element */}
                    <Typewriter
                        words={[type1, type2, type3, type4, type5, type6, type7, type8]}
                        loop={1}
                        cursor
                        typeSpeed={70}
                        deleteSpeed={100}
                        delaySpeed={2000}
                        onLoopDone={handleDone}
                        onType={handleType}
                    />
                </span>
                <br />
                at the speed of social
            </h1>
            <div className='row '>
                <div className='col-md-8 mb-md-5 mb-sm-3  mb-2 pt-xl-4'>
                    <p className='text-dark'>Today’s brands need to adapt quickly to make it to tomorrow. <br className='d-md-block d-none'/> Understand and engage with your customers at the speed of social with ParrotIQ, the social suite built for our fast-moving world.
                    </p>
                </div>
                <div className='col-md-4 d-flex flex-column gap-3 mb-5 more'>
                    <Link to="/parrotiq/demo">
                    <Button className='btn btn-success w-100 py-3'>Get started <FaArrowRight className='ms-3' /></Button>
                    </Link>
                    <Button className='btn btn-outline-success text-dark w-100 py-3'>Explore our suite <FaArrowRight  className='ms-3' /></Button>
                </div>
            </div>

        </section>
    )
}

export default Typewrite