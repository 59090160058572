import React, { useState } from "react";
import "../../styles/components/SinglePage/_navbar.scss"
import Headerlogo from "./HeaderLogo";
import { Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa';

const Navbar = () => {
    const nav = [
        {
            nav: "Our Suite",
            path: ""
        },
        {
            nav: "Use Cases",
            path: ""
        },
        {
            nav: "Industries",
            path: ""
        },
        {
            nav: "Roles",
            path: ""
        },
        {
            nav: "Plans",
            path: ""
        },
        {
            nav: "Resources",
            path: ""
        },
    ]

    const [isActive, setActive] = useState(false)
    return (
        <header className={`py-4 sticky-top bg-white ${isActive ? "mobile-nav" : "desktop-nav"}`} >
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-12 d-flex  justify-content-between">
                        <Headerlogo />
                        <div className="d-xl-block d-xl-none toggle-button cursor-pointer" onClick={() => setActive(!isActive)}><FaBars/></div>
                    </div>
                    <div className="col-xl-6 pt-0 pt-xl-2 col-12">
                        <nav >
                            <ul className="d-xl-flex justify-content-xl-between ">
                                {nav.map((item, index) => {
                                    return (
                                        <li key={index} onClick={() => setActive(false)}>
                                            <Link to={item.path} className="text-decoration-none fw-semibold ">{item.nav}</Link>
                                        </li>
                                    )
                                })}

                            </ul>
                        </nav>
                    </div>
                    <div className="col-xl-3 text-xl-end text-center col-12">
                        <Link to="/parrotiq/demo" onClick={() => setActive(false)} className="btn btn-outline-success text-xl-dark">Get started</Link>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Navbar