import React, { useState } from 'react'
import Img1 from '../../assets/img/carosal/img1.jpg'
import Img1_1 from '../../assets/img/carosal/img1-1.svg'


import Img2 from '../../assets/img/carosal/img2.jpg'
import Img2_2 from '../../assets/img/carosal/img2-2.svg'

import Img3 from '../../assets/img/carosal/img3.jpg'
import Img3_3 from '../../assets/img/carosal/img3-3.svg'

import Img4 from '../../assets/img/carosal/img4.jpg'
import Img4_4 from '../../assets/img/carosal/img4-4.svg'

import '../../styles/components/SinglePage/_carousel.scss'
import Card, { CardBody, CardHeader, CardTitle } from '../../components/bootstrap/Card'
import Button from '../../components/bootstrap/Button'


const Carousel = () => {
    const carousel = [
        {
            img1: Img1,
            img2: Img1_1,
            title: <div>Better audience <span style={{ color: "#ff6d56" }}>engagement</span></div>,
            description: "Virgin Holidays brought award-winning innovative content discovery to the travel industry to achieve excellent readership results.",
            color: "#ff6d56"
        },
        {
            img1: Img2,
            img2: Img2_2,
            title: <div>Take the  <span style={{ color: "#8ac539" }}>lead</span></div>,
            description: "Virgin Holidays brought award-winning innovative content discovery to the travel industry to achieve excellent readership results.",
            color: "#8ac539"
        },
        {
            img1: Img3,
            img2: Img3_3,
            title: <div>Double the  <span style={{ color: "#f99132" }}>ROI</span></div>,
            description: "Virgin Holidays brought award-winning innovative content discovery to the travel industry to achieve excellent readership results.",
            color: "#f99132"
        },
        {
            img1: Img4,
            img2: Img4_4,
            title: <div>Turn crisis into  <span style={{ color: "#ffbe0a" }}>opportunity</span></div>,
            description: "Virgin Holidays brought award-winning innovative content discovery to the travel industry to achieve excellent readership results.",
            color: "##ffbe0a"
        },

    ]

    const [isCarousel, setCarousel] = useState(0)

    return (
        <section>
            <div className='carousel' >
                {carousel.map((item, index) => {
                    const { img1, img2, title, description, color } = item
                    return (
                        <>
                            {isCarousel === index && <>
                                <img src={img1} className='img-fluid main-img'  key={`'img'+${index}`} />
                                <div className='card bg-white ' key={`'card'+${index}`}>
                                    <div  className='text-center card-img-top bg-white'>
                                        <img src={img2} className='rounded-circle mt-3' />
                                    </div>
                                    <div className='card-body'>
                                        <div className='card-title'>
                                            {title}
                                        </div>
                                        <p className='text-dark font-normal pt-2'>{description}</p>
                                        <Button className='text-dark fw-semibold px-0'>Read More</Button>
                                    </div>
                                </div>
                            </>}
                        </>
                    )
                })}
                <br />

                <div className='pagination d-flex gap-2 justify-content-center mt-4' >
                    {carousel.map((x, index) => {
                        return (
                            <Button key={`'btn'+${index}`} className={`btn p-0 rounded-circle ${isCarousel === index ? "btn-success" : "btn-secondary"}`} onClick={() => setCarousel(index)}></Button>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
export default Carousel



