import React from "react";
import Img1 from '../../assets/img/New folder/5.jpg'
import Component from "./Component";
const Trend = () => {
    const trend = [
        {
            img: Img1,
            title: <div>React to the <span className="text-success">trends</span> that matter</div>,
            description: <div> Access the world’s largest archive of consumer opinion and leverage industry-leading AI to <span className="fw-bold"> discover new trends before anyone else and make smarter decisions.</span></div>,
            path: ""
        }
    ]
    return (
        <section>
            {trend.map((item, index) => {
                return (
                    <Component key={index} {...item} />
                )
            })}
        </section>
    )
}
export default Trend