import React from 'react'
import { socialIcon } from '../../menu'
import "../../styles/components/SinglePage/_socialchannel.scss"
const SocialChannel = () => {
    return (
        <section className='social'>
         <div className='_content'>
         <li className='fw-semibold text-secondary'>ALL-IN-ONE SUITE FOR ALL YOUR SOCIAL MEDIA CHANNELS </li>
            <div className='mt-4 social-icon'>
                {socialIcon.map((item, index) => {
                    return (
                        <div className='d-flex justify-content-center bg-custom rounded-circle mb-3' key={index} >
                            <img src={item.img} />
                        </div>
                    )
                })}

            </div>
         </div>
        </section>
    )
}
export default SocialChannel