import React from "react";
import Img1 from '../../assets/img/New folder/8.jpg'
import Component from "./Component";
const Manage = () => {
    const Manage1 = [
        {
            img: Img1,
            imgWidth:"100%",
            imgHight:"70vh",
            title: <div><span className="text-success">Manage </span>all channels with ease</div>,
            description:
                <div>Take the stress out of community management and customer support. <span className="fw-bold">Engage customers and prospects with one social inbox for all your networks. </span></div>,
            path: "",
            order: "order-2",
             top:"100px"
             
             
        }
    ]
    return (
        <section>
            {Manage1.map((item, index) => {
                return (
                    <Component key={index} {...item} />
                )
            })}
        </section>
    )
}
export default Manage