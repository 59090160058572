import React from 'react'
import "../../styles/components/SinglePage/_solution.scss"
import Button from '../../components/bootstrap/Button'
import Card, { CardBody } from '../../components/bootstrap/Card'
import { FaArrowRight } from "react-icons/fa";

const Solution = () => {
    const solution = [
        {
            heading: "Use Cases",
            color: "#8ac539",
            path: "",
        },
        {
            heading: "Industries",
            color: "#f99132",
            path: "",
        },
        {
            heading: "Roles",
            color: "#ffbe0a",
            path: "",
        },

    ]

    return (
        <section className='solution'>
            <div className="row">
                <div className="col-md-6 info mb-4">
                    <div className='_content'>
                        <h1 className="fw-bold text-dark title ">A solution that <span className="text-success">fits</span></h1>
                        <p className="desc">
                            Select your job role, your industry or your use case and we’ll show how ParrotIQ can help.
                        </p>
                        <Button className="btn btn-outline-success text-dark  px-4 py-3 d-flex mt-5">Get started <FaArrowRight  className="mt-1 ms-3" />
                        </Button>
                    </div>
                </div>
                <div className='col-md-4 offset-md-1 offset-lg-0 fits'>
                    <div className='_content w-100'>
                        {solution.map((item, index) => {
                            const { heading, color, path } = item
                            return (
                                <Card className='pt-0 bg-light rounded-0 cursor-pointer' key={index}>
                                    <CardBody className='p-0'>
                                        <h4 className='fw-semibold mb-0'>{heading}</h4>
                                        <div className="border-top"></div>
                                        <div className='d-flex gap-3 more'>
                                            <h5 className='mb-2'>Explore</h5>
                                            <span className='badge p-0 rounded-circle d-flex justify-content-center' style={{ backgroundColor: color }}><FaArrowRight />
                                            </span>
                                        </div>
                                    </CardBody>
                                </Card>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Solution