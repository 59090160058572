import React from "react";
import "../../styles/components/SinglePage/component.scss"
import Button from "../../components/bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";

const Component = ({ img, title, description, order }: any) => {
    return (
        <section className="component">
            <div className="row ">
                <div className={`col-md-5 image mb-5 mb-md-0 ${order}`}>
                    <img src={img} className="img-fluid" />
                </div>
                <div className="col-md-6 offset-md-1 offset-0 mb-5 mb-md-0 info">
                    <div className="_content">
                        <h1 className="fw-bold text-dark title">{title}</h1>
                        <p className="desc  py-3">{description}</p>
                        <Button className="btn btn-outline-success text-dark  px-4 py-3 d-flex">Get started <FaArrowRight   className="mt-1 ms-3" />
                        </Button>


                    </div>
                </div>

            </div>

        </section>
    )
}
export default Component