import React from 'react'
import Img from '../../assets/img/New folder/review.jpg'
import '../../styles/components/SinglePage/_review.scss'
const Review=()=>{
    return(
        <section className='review'>
          <div className='row'>
            <div className='col-md-2 d-flex justify-content-center'>
                <img src={Img} className='img-fluid shadow-sm rounded-circle'/>
            </div>
            <div className='col-md-10 pt-3 text-center text-md-start'>
                <h4 className='text-dark fw-semibold mb-4 pt-4 pt-lg-0'>“My favourite thing about ParrotIQ is the ability to really customize the data. We’re able to customize what we’re looking for and the audiences we’re speaking to.”</h4>
                  <span className='text-secondary fw-semibold'>Jaya Deshpande</span>, <span>Principal Social Analyst, BBC</span>
            
            </div>
          </div>
        </section>
    )
}
export default Review