import React from "react";
import Card, { CardBody, CardFooter } from "../../components/bootstrap/Card";
import "../../styles/components/SinglePage/_footer.scss"
import { Link } from "react-router-dom";
import Button from "../../components/bootstrap/Button";
import { AiFillYoutube, AiFillInstagram } from 'react-icons/ai'
import { ImLinkedin2 } from 'react-icons/im'
import { TiSocialTwitter } from 'react-icons/ti'
import { FaFacebookF } from 'react-icons/fa'
const Footer = () => {
    const year = new Date().getFullYear()

    const footer = [
        {
            title: "Solution",
            link1: "Consumer intelligence",
            link2: "Social Media Management",
            link3: "Influencer Marketing",
            link4: "APIs"
        },
        {
            title: "ParrotIQ for Education",
            link1: "Students",
            link2: "Classrooms",
            link3: "Research",
            link4: "Administration"
        },
        {
            title: "Resources",
            link1: "Blog",
            link2: "Reports",
            link3: "Case Studies",
            link4: "Guides",
            link5: "Webinars"
        },
        {
            title: "Company",
            link1: "About Us",
            link2: "Careers",
            link3: "Contact Us",
            link4: "Press",
            link5: "Partnerships",
            link6: "Environmental Commitment",
            link7: "Awards",
            link8: "Our Evolution"
        },
        {
            title: "Legal",
            link1: "Legal Hub",
            link2: "User Privacy Statement",
            link3: "Author Privacy Statement",
            link4: "Term & Condition",
            link5: "Information Security",
            link6: "Gender Pay Gap Report",
            link7: "Modern Slavery Act Statement",
            link8: "Do Not Sell My Personal Data"
        },
        {
            title: "More",
            link1: "Customer Support",
            link2: "Customer Success",
            link3: "Help Center",
            link4: "Platform Status"
        },

    ]

    const socialIcon = [
        {
            icon: <TiSocialTwitter />
        },
        {
            icon: <FaFacebookF />
        },
        {
            icon: <ImLinkedin2 />
        },
        {
            icon: <AiFillInstagram />
        },
        {
            icon: <AiFillYoutube />
        },
    ]
    return (
        <section className="_footer">
            <Card className="shadow-none border-top rounded-0  text-center text-sm-start mb-0">
                <div className="container">
                    <CardBody>
                        <>
                            {footer.map((item, index) => {
                                const { title, link1, link2, link3, link4, link5, link6, link7, link8 } = item
                                return (
                                    <div className="footer-navs  pt-5 pt-xl-0" key={index}>
                                        <h6 className="title fw-semibold pb-2">{title}</h6>
                                        <ul>
                                            <li> <Link to="#" className="text-decoration-none fw-normal">{link1}</Link></li>
                                            <li> <Link to="#" className="text-decoration-none fw-normal">{link2}</Link></li>
                                            <li> <Link to="#" className="text-decoration-none fw-normal">{link3}</Link></li>
                                            <li> <Link to="#" className="text-decoration-none fw-normal">{link4}</Link></li>
                                            <li> <Link to="#" className="text-decoration-none fw-normal">{link5}</Link></li>
                                            <li> <Link to="#" className="text-decoration-none fw-normal">{link6}</Link></li>
                                            <li> <Link to="#" className="text-decoration-none fw-normal">{link7}</Link></li>
                                            <li> <Link to="#" className="text-decoration-none fw-normal">{link8}</Link></li>
                                        </ul>
                                    </div>
                                )
                            })}
                            <div className="footer-navs pt-5 pt-xl-0">
                                <h6 className="title fw-semibold pb-2">The ParrotIQ Bulletin</h6>
                                <ul>
                                    <li className="text-secondary fw-normal">
                                        All our latest data stories and insights straight to your inbox
                                    </li>
                                </ul>
                                <Button className="btn btn-outline-success w-100 mt-3 mb-4" >Sign up</Button>
                                <Link to="#" className="fw-semibold text-dark">English</Link>
                            </div>
                        </>


                    </CardBody>
                </div>
                <CardFooter className="bg-light rounded-0 border-top">
                    <div className="container">
                        <div className="d-xl-flex  justify-content-xl-between  text-xl-start text-center">
                            <div >
                                <span className="d-block"> Copyright &copy; {year} ParrotIQ. All Rights Reserved. 1st Floor, Sovereign House, Church Street, Brighton, BN1 1UJ</span>
                                <span>Company number: 0311 111111 | VAT number: 754 750 710</span>

                            </div>
                            <div className="social-media d-flex gap-3 justify-content-center mt-2 mt-xl-0">
                                {socialIcon.map((item, index) => {
                                    return (
                                        <Link to="" className="social-icon text-dark" key={index}>
                                            {item.icon}
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </CardFooter>

            </Card>
        </section>
    )
}
export default Footer