import React from "react";
import "../../styles/components/SinglePage/_navbar.scss"
import logo from '../../assets/images/logo.png'
import { Link } from "react-router-dom";

const Headerlogo = () => {
    return (
        <div className="d-flex gap-2 header-comapny--logo">
            <Link to="/">
            <img src={logo} alt="" className="img-fluid"/>
            </Link>
        </div>
    )
}
export default Headerlogo